import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './styles.css';
import RangeSlider from 'react-bootstrap-range-slider';

function App() {
  const [ audioSpeed, setAudioSpeed ] = useState(''); 

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <a
          className="App-link"
          href="https://sahir.fans"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn Recursion
        </a>
        <RangeSlider
          value={audioSpeed}
          min={0.2}
          max={2.2}
          step={0.2}
          onChange={changeEvent => setAudioSpeed(changeEvent.target.value)}
        />
      </header>
      <Frame/>
    </div>
  );
}

const Frame = () => {
  return (
    <div className="frame">
      <div className="div">
        <div className="text-wrapper">sahir.fans</div>
        <p className="p">
          hello friend, sahir is just two syllables, but the second one might be tricky so thanks for learning how to
          pronounce 👌
        </p>
        <p className="ayy-click-here-to">
          <span className="span">🎤 </span>
          <span className="text-wrapper-2">ayy click here to record</span>
          <span className="span"> 🎤&nbsp;&nbsp;</span>
        </p>
        <div className="text-wrapper-3">Audio speed</div>
        <div className="text-wrapper-4">TY :)</div>
        <div className="overlap">
          <div className="text-wrapper-5">Correct voice</div>
        </div>
        <div className="overlap-group">
          <div className="text-wrapper-5">Correct voice</div>
        </div>
        <div className="div-wrapper">
          <div className="text-wrapper-6">Incorrect voice</div>
        </div>
        <div className="overlap-2">
          <div className="text-wrapper-6">Incorrect voice</div>
        </div>
        <div className="overlap-3">
          <div className="text-wrapper-6">Incorrect voice</div>
        </div>
        <div className="text-wrapper-7">✅ Do’s</div>
        <div className="text-wrapper-8">😑 Dont’s</div>
        <div className="vector-wrapper">
          <img className="vector" alt="Vector" src="vector-1.svg" />
        </div>
        <div className="img-wrapper">
          <img className="img" alt="Vector" src="vector-2.svg" />
        </div>
      </div>
    </div>
  );
};

export default App;
